import React from 'react';

// Utils
import { isUserCompany } from './JourneyUtils';
import { selectDefaultBankName } from './CatalizrUtils';

// Assets
import IconAlertTriangle from '../../iconsPictos/iconAlertTriangle';
import IconFlag from '../../iconsPictos/iconFlag';
import IconBell from '../../iconsPictos/iconBell';
import IconChat from '../../iconsPictos/iconChat';
import IconMoney from '../../iconsPictos/iconMoney';

// to access react table setColumns
export const TABLE_COLUMNS_HEADERS = {
  // SPECIFIC BULK_TABLE
  // display for banks
  ADVISOR_EMAIL: {
    id: 'advisor_email',
    header: 'advisor',
    accessor: 'advisor_email',
  },
  BANK_SUBDOMAIN: {
    id: 'bank_subdomain',
    header: 'bank_name',
    accessor: 'bank_subdomain',
  },
  CATEGORY: {
    id: 'category',
    header: 'category',
    accessor: 'category',
  },

  INVESTOR_NAME_SURNAME: {
    id: 'investor_name_surname',
    header: 'investor',
    accessor: 'investor_name_surname',
  },
  CO_INVESTOR_NAME_SURNAME: {
    id: 'co_investor_name_surname',
    header: 'co_investor',
    accessor: 'co_investor_name_surname',
  },

  NB_PART: {
    id: 'nb_part',
    header: 'quantity',
    accessor: 'nb_part',
  },

  PART_TYPE: {
    id: 'part_type',
    header: 'part_type',
    accessor: 'part_type',
  },

  PORTFOLIO_TYPE: {
    id: 'portfolio_type',
    header: 'portfolio_type',
    accessor: 'portfolio_type',
  },

  TOTAL_INVEST: {
    id: 'total_invest',
    header: 'total_invest',
    accessor: 'total_invest',
  },

  // SPECIFIC FOR OPERATION_TEMPLATE_TABLE

  BRIEFCASE: {
    id: 'origin',
    header: 'template',
    accessor: 'origin',
  },

  ACTION_BY_UUID: {
    id: 'uuid',
    header: 'actions',
    accessor: 'uuid',
  },

  BANK_ACTIVE_TEMPLATE: {
    id: 'bank_activated_name',
    header: intl =>
      isUserCompany()
        ? intl.formatMessage({ id: 'table.columns.banks_count' })
        : intl.formatMessage({ id: 'table.columns.banks' }),
    accessor: 'bank_activated_name',
  },

  COUNT_CANCELLED_OPERATIONS: {
    id: 'count_cancelled_operations',
    header: 'files_cancelled',
    accessor: 'count_cancelled_operations',
  },

  COUNT_CLOSED_OPERATIONS: {
    id: 'count_closed_operations',
    header: 'files_closed',
    accessor: 'count_closed_operations',
  },

  COUNT_TRANSMIT_OPERATION: {
    id: 'count_transmit_operations',
    header: 'files_transmitted',
    accessor: 'count_transmit_operations',
  },

  OPERATION_TYPE_ID: {
    id: 'operation_type_id',
    header: 'operation_type',
    accessor: 'operation_type_id',
  },

  PORTFOLIO_TYPE_ID: {
    id: 'portfolio_types_id',
    header: 'account',
    accessor: 'portfolio_types_id',
  },

  TEMPLATE_NAME: {
    id: 'template_name',
    header: 'template_name',
    accessor: 'template_name',
  },

  RESET_TEMPLATE: {
    id: 'is_modified',
    header: 'to_reset',
    accessor: 'is_modified',
  },

  // SPECIFIC FOR CLOSED_TABLE

  // display for banks
  LAST_COMMENT: {
    id: 'last_comment',
    header: intl => {
      const tooltip = intl.formatMessage({ id: 'tooltip.comments' });
      return <IconChat alt={tooltip} title={tooltip} width="22px" height="22px" />;
    },
    accessor: 'last_comment',
  },

  ISIN_CODE: {
    id: 'isin_code',
    header: 'isin_code',
    accessor: 'isin_code',
  },

  // display for banks with flag 'display.adherent_column_in_dashboard'
  ADHERENT: {
    id: 'adherent_name',
    header: 'adherent_name',
    accessor: 'adherent_name',
  },

  INVESTOR_NAME: {
    id: 'investor_name',
    header: 'investor',
    accessor: 'investor_name',
  },
  CO_INVESTOR_NAME: {
    id: 'co_investor_name',
    header: 'co_investor',
    accessor: 'co_investor_name',
  },

  COMPANY_NAME: {
    id: 'company_name',
    header: intl =>
      selectDefaultBankName() === 'Banque Transatlantique Belgium'
        ? intl.formatMessage({ id: 'table.columns.management_company' })
        : intl.formatMessage({ id: 'table.columns.company' }),
    accessor: 'company_name',
  },

  SELLER_NAME: {
    id: 'seller_name',
    header: 'seller',
    accessor: 'seller',
  },

  COMPANY_BOSS_MAIL: {
    id: 'userCompany.email',
    header: 'company_boss_mail',
    accessor: 'userCompany.email',
  },

  OPERATION_TYPE: {
    id: 'operation_type',
    header: 'operation',
    accessor: 'operation_type',
  },

  // display for banks and investor
  INVESTOR_IBAN: {
    id: 'investor_iban',
    header: 'investor_iban',
    accessor: 'investor_iban',
  },

  INVESTOR_SIGNED: {
    id: 'investor_signed',
    header: 'investor_signed',
    accessor: 'investor_signed',
  },

  INVESTMENT_DATE: {
    id: 'investment_date',
    header: 'date.investment',
    accessor: 'real_estimated_invest_date',
  },

  TRADING_ACCOUNT_NUMBER: {
    id: 'trading_account_number',
    header: 'trading_account_number',
    accessor: 'trading_account_number',
  },

  SEND_DATE: {
    id: 'date_subscribe',
    header: 'date.sent',
    accessor: 'date_subscribe',
  },

  CLOSED_OR_CANCEL_DATE: {
    id: isUserCompany() ? 'status_change_date' : 'filtered_status_date',
    header: null,
    accessor: isUserCompany() ? 'status_change_date' : 'filtered_status_date',
  },
  // SPECIFIC FOR INVESTMENT HISTORY
  CREATED_DATE: {
    id: 'createdAt',
    header: 'date.sent',
    accessor: 'createdAt',
  },
  STEP: {
    id: 'step',
    header: 'step',
    accessor: 'step',
  },
  ENTITY_TYPE: {
    id: 'entity_type',
    header: 'entity_type',
    accessor: 'entity_type',
  },
  AUTHOR: {
    id: 'author_email',
    header: 'author',
    accessor: 'author_email',
  },
  EMAIL: {
    id: 'email',
    header: 'email',
    accessor: 'email',
  },
  // FUNDS
  FUND_NAME: {
    id: 'fund_name',
    header: 'fund_name',
    accessor: 'fund_name',
  },
  FUND_ISIN: {
    id: 'fund_isin',
    header: 'fund_isin',
    accessor: 'fund_isin',
  },

  // SPECIFIC FOR SHAREVALUATION LIST
  EMAIL_ICON: {
    id: 'email_sent',
    header: 'email_sent',
    accessor: 'email_sent',
  },
  RAISON_SOCIALE: {
    id: 'company_name',
    header: 'company_name',
    accessor: 'company_name',
  },
  SIREN: {
    id: 'company_siren',
    header: 'company_siren',
    accessor: 'company_siren',
  },
  N_VALEUR: {
    id: 'nb_value',
    header: 'nb_value',
    accessor: 'nb_value',
  },
  N_INVESTISSEURS: {
    id: 'nb_investor',
    header: 'nb_investor',
    accessor: 'nb_investor',
  },
  UPDATE_DATE: {
    id: 'updatedAt',
    header: 'date.updated',
    accessor: 'updatedAt',
  },
  VALORISATIONS_ANNUELLES: {
    id: 'ost_valorisation',
    header: 'ost_valorisation',
  },
  CONFIRMATION_POSITIONS: {
    id: 'ost_shareholding',
    header: 'ost_shareholding',
  },
  DIVIDEND_PAYMENT: {
    id: 'ost_dividends',
    header: 'ost_dividends',
  },

  // SPECIFIC FOR SHAREVALUATION DETAILS

  // INVESTISSEURS vs N_INVESTISSEURS : get investors name
  INVESTISSEURS: {
    id: 'investors',
    header: 'nb_investor',
    accessor: 'investors',
  },
  PREVIOUS_COURSE: {
    id: 'previous_price',
    header: 'previous_price',
    accessor: 'previous_price',
  },
  CURRENT_COURSE: {
    id: 'current_price',
    header: 'current_price',
    accessor: 'current_price',
  },
  NEW_VALUE: {
    id: 'price',
    header: 'price',
    accessor: 'price',
  },
  EFFECTIVE_DATE: {
    id: 'effective_date',
    header: 'date.effective',
    accessor: 'effective_date',
  },
  NEW_EFFECTIVE_DATE: {
    id: 'new_effective_date',
    header: 'date.new_effective',
    accessor: 'new_effective_date',
  },
  // specifix share valuation history
  VALUATION_DOC: {
    id: 'proof_doc_id',
    header: 'proof_doc_id',
    accessor: 'proof_doc_id',
  },

  // SPECIFIC FOR SHAREHOLDING
  QUANTITY: {
    id: 'quantity',
    header: 'quantity',
    accessor: 'quantity',
  },
  PART_AMOUNT: {
    id: 'part_amount',
    header: 'part_amount',
    accessor: 'part_amount',
  },

  // SPECIFIC FOR SHAREHOLDING INVESTOR WALLET
  PAYMENT_MODE: {
    id: 'payment_mode',
    header: 'account',
    accessor: 'payment_mode',
  },
  QUANTITY_MVT: {
    id: 'quantity_delta',
    header: 'quantity_delta',
    accessor: 'quantity',
  },
  QUANTITY_VALIDATED: {
    id: 'quantity_confirmed',
    header: 'quantity_validated',
    accessor: 'quantity_confirmed',
  },
  DATE_POSITION_VALIDATED: {
    id: 'last_validation_date',
    header: 'last_validation_date',
    accessor: 'last_validation_date',
  },
  BANK_NAME: {
    id: 'bank_name',
    header: 'bank',
    accessor: 'bank_name',
  },
  INVESTOR_WALLET_EDITING: {
    id: 'manually_inserted',
    header: 'origin',
    accessor: 'manually_inserted',
  },
  INVESTOR_WALLET_DELETE: {
    id: 'manually_deleted',
    header: 'actions',
    accessor: 'manually_inserted',
  },

  INVESTOR_POSITION_ATTESTATION: {
    id: 'uuid',
    header: 'confirm_position_doc',
    accessor: 'confirm_position_doc',
  },

  INVESTOR_POSITION_ACTION: {
    id: 'actions',
    header: 'actions',
    accessor: 'uuid',
  },

  // SPECIFIC FOR POSITIONS DETAILS
  CONFIRMED_QUANTITY: {
    id: 'confirmed_quantity',
    header: 'quantity_confirmed',
    accessor: 'quantity',
  },

  // SPECIFIC FOR TRANSMIT
  INVESTOR_EMAIL: {
    id: 'investor_email',
    header: 'investor',
    accessor: 'investor_email',
  },
  EMAIL_STATUS: {
    id: 'ctaEmailsStatus',
    header: 'status',
    accessor: 'ctaEmailsStatus',
  },
  UPDATE_TRANSMIT: {
    id: 'modifier transmis',
    header: 'to_modify',
    accessor: 'id',
  },
  CANCEL_OPERATION: {
    id: 'annuler',
    header: 'to_cancel',
    accessor: 'id',
  },
  OPERATION_ACTIONS: {
    id: 'actions',
    header: 'actions',
    accessor: 'id',
  },
  CONFIRM_OPERATION: {
    id: 'confirm',
    header: 'actions',
    accessor: 'id',
  },

  // SPECIFIC FOR IN PROGRESS LIST
  RISK_INDICATOR: {
    id: 'risk_indicator',
    accessor: 'risk_indicator',
    header: intl => {
      const tooltip = intl.formatMessage({ id: 'tooltip.risk_indicator' });
      return <IconAlertTriangle alt={tooltip} title={tooltip} width="25px" height="25px" />;
    },
  },

  FLAG_INDICATOR: {
    id: 'is_flagged',
    accessor: 'is_flagged',
    header: intl => {
      const tooltip = intl.formatMessage({ id: 'tooltip.flag_indicator' });
      return <IconFlag alt={tooltip} title={tooltip} width="25px" height="25px" />;
    },
  },

  ACTIVE_ACTOR: {
    id: 'active_actor',
    header: 'active_actor',
    accessor: 'active_actor',
  },
  OPERATION_PROGRESSION: {
    id: 'progression',
    header: 'progression',
    accessor: 'progression',
  },
  REMINDER: {
    id: 'reminder_occurrence',
    header: intl => {
      const tooltip = intl.formatMessage({ id: 'tooltip.reminder' });
      return <IconBell alt={tooltip} title={tooltip} width="25px" height="25px" />;
    },
    accessor: 'reminder_occurence',
  },

  CHECK_PAYMENT: {
    id: 'check_payment',
    header: intl => {
      const tooltip = intl.formatMessage({ id: 'tooltip.transfer_indicator' });
      return <IconMoney alt={tooltip} title={tooltip} width="25px" height="25px" />;
    },
    accessor: 'check_payment',
  },

  // CHECKBOX IN BULK
  CHECKBOX: {
    id: 'checkbox',
  },

  // SPECIFIC FOR PLATINE
  PLATINE_DOCUMENTS_LIST: {
    id: 'documents_type',
    header: 'document_type',
    accessor: 'fileType',
  },

  PLATINE_DOCUMENT_NAME: {
    id: 'document_name',
    header: 'document_name',
    accessor: 'name',
  },

  PLATINE_CREATION_DATE: {
    id: 'creation_date',
    header: 'date.creation',
    accessor: 'dateCreate',
  },

  PLATINE_USER: {
    id: 'user',
    header: 'user',
    accessor: 'createUser',
  },
  PLATINE_COMMENT: {
    id: 'comment',
    header: 'comment',
    accessor: 'comment',
  },
  PLATINE_COMMENT_CREATION_DATE: {
    id: 'comment_creation_date',
    header: 'date.creation',
    accessor: 'dateCreate',
  },

  // dividende/coupon
  DEPOSIT_ACCOUNT: {
    id: 'deposit_account',
    header: 'iban',
    accessor: 'deposit_account',
  },
  AMOUNT_PER_PART: {
    id: 'amount_per_part',
    header: 'amount_per_part',
    accessor: 'amount_per_part',
  },
  AMOUNT_TO_DEPOSIT: {
    id: 'amount_to_deposit',
    header: 'amount_to_deposit',
    accessor: 'amount_to_deposit',
  },
  AMOUNT_DEPOSIT: {
    id: 'amount_deposit',
    header: 'amount_deposit',
    accessor: 'amount_deposit',
  },
  IBAN: {
    id: 'iban',
    header: 'iban',
    accessor: 'iban',
  },
  PAYMENT_IBAN: {
    id: 'payment_iban',
    header: 'iban_payment',
    accessor: 'payment_iban',
  },
  NB_INVESTISSEURS: {
    id: 'nb_investor',
    header: 'nb_investor',
    accessor: 'nb_investor',
  },
  TRANSFER_DATE: {
    id: 'transfer_date',
    header: 'date.transfer',
    accessor: 'transfer_date',
  },
  DECLARATION_DATE: {
    id: 'declaration_date',
    header: 'date.declaration',
    accessor: 'declaration_date',
  },
  HISTORY_DETAIL: {
    id: 'history_detail',
    header: 'details',
    accessor: 'history_detail',
  },
  PROOF_DOC: {
    id: 'proof_doc',
    header: 'proof_doc',
    accessor: 'proof_doc',
  },
  INVESTOR: {
    id: 'investor',
    header: 'investor',
    accessor: 'investor',
  },
  CO_INVESTOR: {
    id: 'co_investor',
    header: 'co_investor',
    accessor: 'co_investor',
  },
  COUNTRY: {
    id: 'country',
    header: 'country',
    accessor: 'country',
  },
  INVESTOR_REGISTERED_COUNTRY: {
    id: 'investor_registered_country',
    header: 'country',
    accessor: 'investor_registered_country',
  },
  POINTED: {
    id: 'pointed',
    header: 'pointed',
    accessor: 'pointed',
  },

  // SPECIFIC FOR MOVEMENT ORDERS

  ACTION: {
    id: 'action',
    header: 'actions',
    accessor: 'statusCode',
  },
  EMITTER_NAME: {
    id: 'emitter_name',
    header: 'company_name',
    accessor: 'emitter.name',
  },
  INVESTOR_IDENTITY: {
    id: 'investor_identity',
    header: 'investor_identity',
    accessor: 'holders[0].identity',
  },
  MOVEMENT_ORDER_REASON: {
    id: 'movement_order_reason',
    header: 'odm_reason',
    accessor: 'operationType',
  },
  MOVEMENT_ORDER_STATUS: {
    id: 'movement_order_status',
    header: 'odm_status',
    accessor: 'statusName',
  },
  MOVEMENT_ORDER_ISSUE_DATE: {
    id: 'movement_order_issue_date',
    header: 'date.emitted',
    accessor: 'creationDate',
  },
  MOVEMENT_ORDER_DETAILS: {
    id: 'movement_order_details',
    header: 'details',
    accessor: 'movement_order_details',
  },

  // fund call
  FUND_CALL_BANK_NAME: {
    id: 'bank_name',
    header: 'bank',
    accessor: 'name',
  },
  FUND_CALL_INVESTOR_NUMBER: {
    id: 'investor_number',
    header: 'investors',
    accessor: 'investorNumber',
  },
  FUND_CALL_RAISED_AMOUNT: {
    id: 'raised_amount',
    header: 'commitment',
    accessor: 'raisedAmount',
  },
  FUND_CALL_APPEAL_AMOUNT: {
    id: 'appeal_amount',
    header: 'appeal_amount',
    accessor: 'appealAmount',
  },
  FUND_CALL_DEBITING_AMOUNT: {
    id: 'debiting_amount',
    header: 'debiting_amount',
    accessor: 'debitingAmount',
  },
  FUND_CALL_SHARE_PREMIUM: {
    id: 'share_premium',
    header: 'share_premium',
    accessor: 'sharePremium',
  },
  FUND_CALL_CATCH_UP_AMOUNT: {
    id: 'catch_up_amount',
    header: 'catch_up_amount',
    accessor: 'catchUpAmount',
  },

  FUND_CALL_OPERATION_INVESOR_ID: {
    id: 'investor_identity',
    header: 'identity',
    accessor: 'investor.identity',
  },

  FUND_CALL_OPERATION_BANK_NAME: {
    id: 'bank_name',
    header: 'bank',
    accessor: 'bank.name',
  },

  FUND_CALL_OPERATION_SUBSCRIPTION_DATE: {
    id: 'engagement_date',
    header: 'date.subscription',
    accessor: 'createdAt',
  },

  FUND_CALL_OPERATION_RAISED_AMOUNT: {
    id: 'raised_amount',
    header: 'commitment',
    accessor: 'raisedAmount',
  },

  FUND_CALL_OPERATION_APPEAL_AMOUNT: {
    id: 'appeal_amount',
    header: 'appeal_amount',
    accessor: 'appealAmount',
  },

  FUND_CALL_OPERATION_DEBITING_AMOUNT: {
    id: 'debiting_amount',
    header: 'debiting_amount_total',
    accessor: 'debitingAmount',
  },

  FUND_CALL_OPERATION_SHARE_PREMIUM: {
    id: 'share_premium',
    header: 'share_premium',
    accessor: 'sharePremium',
  },

  FUND_CALL_OPERATION_CATCH_UP_AMOUNT: {
    id: 'catch_up_amount',
    header: 'catch_up_amount',
    accessor: 'catchUpAmount',
  },

  // fund summary list
  FUND_APPEAL_STATUS: {
    id: 'status',
    header: 'status',
    accessor: 'status',
  },
  FUND_APPEAL_NAME: {
    id: 'name',
    header: 'fund',
    accessor: 'name',
  },
  FUND_APPEAL_ISIN: {
    id: 'isin',
    header: 'isin',
    accessor: 'isin',
  },
  FUND_APPEAL_PART_TYPE: {
    id: 'partType',
    header: 'part_type',
    accessor: 'partType',
  },
  FUND_APPEAL_CATEGORY: {
    id: 'category',
    header: 'category',
    accessor: 'category',
  },
  FUND_APPEAL_RAISED_AMOUNT: {
    id: 'totalRaisedAmount',
    header: 'commitment',
    accessor: 'totalRaisedAmount',
  },
  FUND_APPEAL_NB: {
    id: 'nbAppeals',
    header: 'nb_appeals',
    accessor: 'nbAppeals',
  },
  FUND_APPEAL_TOTAL_RATE: {
    id: 'totalAppealRate',
    header: 'total_appeal_rate',
    accessor: 'totalAppealRate',
  },
  FUND_APPEAL_COMMUNICATION: {
    id: 'communications',
    header: 'communications',
    accessor: 'communications',
  },
  FUND_APPEAL_DETAILS: {
    id: 'details',
    header: 'details',
    accessor: 'details',
  },
  // fund appeal details
  FUND_APPEAL_DETAIL_INVESTOR: {
    id: 'investor',
    header: 'investor',
    accessor: 'raising.investor.identity',
  },
  FUND_APPEAL_DETAIL_CO_INVESTOR: {
    id: 'investor',
    header: 'co_investor',
    accessor: 'raising.coInvestor.identity',
  },
  FUND_APPEAL_DETAIL_BANK: {
    id: 'bank',
    header: 'bank',
    accessor: 'raising.bank.name',
  },
  FUND_APPEAL_DETAIL_OPERATION_DATE: {
    id: 'subscription_date',
    header: 'date.subscription',
    accessor: 'raising.createdAt',
  },
  FUND_APPEAL_DETAIL_COMMITMENT: {
    id: 'commitment',
    header: 'commitment',
    accessor: 'raising.raisedAmount',
  },
  FUND_APPEAL_DETAIL_AMOUNT_PAYMENT: {
    id: 'appealAmount',
    header: 'appeal_amount',
    accessor: 'appealAmount',
  },
  FUND_APPEAL_DETAIL_CATCH_UP_AMOUNT: {
    id: 'catchUpAmount',
    header: 'catch_up_amount',
    accessor: 'catchUpAmount',
  },
  FUND_APPEAL_DETAIL_SHARE_PREMIUM: {
    id: 'sharePremium',
    header: 'share_premium',
    accessor: 'sharePremium',
  },
  FUND_APPEAL_DETAIL_DEBITED_DATE: {
    id: 'debitedDate',
    header: 'date.debiting',
    accessor: 'debitedDate',
  },
  FUND_APPEAL_DETAIL_PAYMENT_DEBITED: {
    id: 'debited',
    header: 'debiting',
    accessor: 'debited',
  },
  // fund communications
  FUND_COMMUNICATION_DATE: {
    id: 'sentDate',
    header: 'date.date',
    accessor: 'sentDate',
  },
  FUND_COMMUNICATION_RECIPIENT: {
    id: 'type',
    header: 'recipients',
    accessor: 'type',
  },
  FUND_COMMUNICATION_SUBJECT: {
    id: 'subject',
    header: 'subject',
    accessor: 'subject',
  },
  FUND_COMMUNICATION_CONTENT: {
    id: 'htmlBody',
    header: 'htmlBody',
    accessor: 'htmlBody',
  },
  FUND_COMMUNICATION_FORWARD: {
    id: 'forward',
    header: 'to_forward',
    accessor: 'forward',
  },
  // custom_fields
  CUSTOM_FIELDS_LABEL: {
    id: 'label',
    header: 'label',
    accessor: 'label',
  },
  CUSTOM_FIELDS_TYPE: {
    id: 'fieldType',
    header: 'type',
    accessor: 'type',
  },
  CUSTOM_FIELDS_OPTIONS_VALUE: {
    id: 'values',
    header: 'values',
    accessor: 'options',
  },
  CUSTOM_FIELDS_DOC_LABEL: {
    id: 'docLabel',
    header: 'documents',
    accessor: 'doc_label',
  },
  CUSTOM_FIELDS_EDIT: {
    id: 'edit',
    header: 'to_modify',
    accessor: 'uuid',
  },
  CUSTOM_FIELDS_TRASH: {
    id: 'trash',
    header: 'to_trash',
    accessor: 'trash',
  },
  CUSTOM_FIELDS_SECTION: {
    id: 'section',
    header: 'section',
    accessor: 'section_uuid',
  },
  CUSTOM_FIELDS_MULTI_COLUMN_NAME: {
    id: 'multi_column_name',
    header: 'multi_column_name',
    accessor: 'options',
  },
  CUSTOM_FIELDS_MULTI_COLUMN_TYPE: {
    id: 'multi_column_type',
    header: 'multi_column_type',
    accessor: 'options',
  },
  IS_CROWDFUNDING: {
    id: 'is_crowdfunding',
    header: 'is_crowdfunding',
    accessor: 'is_crowdfunding',
  },
};

export const TABLE_NAME = {
  INVESTMENT_HISTORY: 'investmentHistoryList',
  OPERATION_TEMPLATE_LIST: 'operationTemplateList',
  CLOSED_LIST: 'closedList',
  IN_PROGRESS_LIST: 'inProgressList',
  TRANSMIT: 'transmitList',
  OPERATION_SUGGESTION: 'operationSuggestion',
  SHARES_VALUATION: 'sharesValuation',
  SHARES_VALUATION_POSITION: 'sharesValuationPosition',
  SHARES_VALUATION_POSITION_DETAILS: 'sharesValuationPositionDetails',
  SHARES_VALUATION_POSITION_DETAILS_HISTORY: 'sharesValuationPositionDetailsHistory',
  SHARES_VALUATION_DETAILS: 'sharesValuationDetails',
  SHARES_VALUATION_HISTORY_DETAILS: 'sharesValuationHistoryDetails',
  BULK_TABLE: 'bulkTable',
  SHAREHOLDING_LIST: 'shareHoldingList',
  INVESTOR_WALLET_TABLE: 'investorWalletTable',
  POSITION_DETAILS_TABLE: 'positionDetailsTable',
  POSITION_HISTORY_DETAILS_TABLE: 'positionHistoryDetailsTable',
  PLATINE_DOCUMENTS_LIST: 'platineDocumentslist',
  PLATINE_COMMENTS_LIST: 'platineCommentslist',
  EXPOST_CHARGES: 'expostCharges',
  EXPOST_CHARGES_PRODUCT: 'expostChargesProductTable',
  EXPOST_CHARGES_INVESTOR: 'expostChargesInvestorTable',
  EXPOST_CHARGES_OPERATION: 'expostChargesOperationTable',
  GENERATED_PDF_INJECTION: 'GeneratedPdfDocumentInjectionTable',
  CUSTOM_FIELDS_TABLE: 'customFieldsTable',
  TEMPLATE_DOCUMENT_TABLE_FIELDS: 'TemplateDocumentTableField',
  DIVIDEND_COUPON_INVESTORS: 'dividendCouponInvestorsTable',
  DIVIDEND_COUPON_REGROUPED_POSITION: 'dividendCouponRegroupedPositionTable',
  DIVIDEND_COUPON_HISTORY: 'dividendCouponHistoryTable',
  DIVIDEND_COUPON_HISTORY_DETAIL: 'dividendCouponHistoryDetailTable',
  MOVEMENT_ORDERS: 'movementOrdersTable',
  FUND_MANAGEMENT: 'fundManagement',
  FUND_APPEAL_DETAILS: 'fundAppealDetails',
  FUND_COMMUNICATION: 'fundCommunication',
  FUND_APPEAL_ESTABLISHMENT: 'establishment table',
  FUND_APPEAL_OPERATION: 'operations table',
  CUSTOM_FIELDS_MANAGEMENT: 'customFieldsManagementTable',
  MULTIPLE_COLUMN_TABLE: 'customizableMultiColumnTable',
};

export const TABLE_TEXT = {
  PAGINATION_INFOS: 'display informations about pagination',
  CHECKBOXES_INFOS: 'display informations about checkbox',
};

export const CHECKBOXES_LABEL = {
  check_payment: 'Virement',
  risk_indicator: 'Indicateur de risque',
  is_flagged: "Indicateur d'attention",
  reminder_occurence: 'Relances',
  last_comment: 'Commentaires',
  email_sent: 'Email',
};

/*
declare module 'react-table' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface TableState<D extends object> extends UseSortByState<D> {}
}
*/
